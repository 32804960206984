<template>
  <div>
     <el-card>
      <search-form ref="searchForm" :tabData="tabData" labelWidth="120px" :topVisible="true" :searchFormData="searchFormData" @search="search" @tabChange="tabChange">
         <template #topBtn>
          <el-button class="marginRight20" type="primary" size="small" @click="handleExport">{{$t('button.orderExport')}}</el-button>
        </template>
      </search-form>
      <create-table
        :operationColWidth="135"
        :autoWidth="true"
        @handleNewTab="handleNewTab($event, 'ordersManagerCouponOrdersDetail')"
        :operateVisible="operateVisible"
        ref="createTable"
        :loading="loading"
        :btnType="[]"
        :total_count="pageData.total_count ? pageData.total_count : 0"
        :current_page.sync="pageData.page"
        :papeSize.sync="pageData.per_page"
        @selectionChange="selectionChange"
        :topButtonVisible="true"
        :tableData="tableData"
        :tableDataItem="tableDataItem"
        @operate="handleClick"
        :pageVisible="true"
        :pageData="pageData"
        :checkBoxVisible="dropDataItem.length > 0"
        @handleSort="handleSort"
        @forwardToPage="forwardToPage"
      >
        <template #topButton>
          <span v-if="dropDataItem.length > 0" class="marginRight20 spanminWith">{{`${$t('text.selected')}：${selectionData.length}`}}</span>
          <allButton :buttonDisabled="selectionData.length < 1" :buttonDataItem="dropDataItem" class="marginRight20" @chooseDropItem="chooseDropItem"></allButton>
        </template>
        <template #rightBtn="scope">
          <el-button class="margintop10" :class="item.action === 'view' ? 'operate_view' : ''" v-for="(item, index) in scope.row.member_actions.default_actions" :key="'default_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
          <el-button class="margintop10" v-for="(item, index) in scope.row.member_actions.custom_actions" :key="'custom_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
        </template>
      </create-table>
    </el-card>
    <router-view/>
    <dialogEffect v-if="dialogVisible" :dialogVisible.sync="dialogVisible" :data="dialogData" @doNextConfirm="doNextConfirm"></dialogEffect>
  </div>
</template>

<script>
import { getCouponOrderSetup, getCounponOrderQueryList, getCouponOrderExport } from '@/services/order'

// import _ from 'lodash'
// import loading from '@/utils/loading.json'
import jsonData from './jsonData.js'
import allButton from '@/components/allButton'
import searchForm from '@/components/searchForm'
import createTable from '@/components/createTable'
import dialogEffect from '@/components/dialogEffect'
import { mixin } from '@/mixins/mixin'
export default {
  name: 'managerUserOrders',
  mixins: [mixin],
  components: {
    searchForm,
    createTable,
    allButton,
    dialogEffect
  },
  data () {
    return {
      // searchFormData: [],
      // dropDataItem: [],
      // selectionData: [],
      tableDataItem: jsonData.tableDataItem
      // tabData: [],
      // tableData: [],
      // loading: false,
      // pageData: {
      //   page: 1,
      //   per_page: 50
      // },
      // searchData: undefined,
      // operateVisible: true
    }
  },
  watch: {
    // 'pageData.page': {
    //   handler () {
    //     this.search()
    //   }
    // },
    // 'pageData.per_page': {
    //   handler () {
    //     this.search()
    //   }
    // },
    $route (to, from) {
      // console.log(from)
      if (from.name === 'managerAfterSalesOrdersView') {
        this.$nextTick(() => {
          this.search()
          this.$refs.createTable.$refs.table.doLayout()
        })
      }
    }
  },
  created () {
    this.queryData(getCouponOrderSetup)
  },
  activated () {
    if (this.isActive) this.queryData(getCouponOrderSetup)
    this.isActive = true
  },
  methods: {
    async forwardToPage (data, name) {
      if (name === 'user_order') {
        this.$router.push({
          name: 'ordersManagerUserOrdersDetail',
          params: {
            id: data.id
          }
        })
      }
      if (name === 'shop') {
        this.$router.push({
          name: 'shopsManagerDetail',
          params: {
            id: data.id
          }
        })
      }
      if (name === 'product_info') {
        this.$router.push({
          name: 'goodsManagerProductDetail',
          params: {
            id: data.id
          }
        })
      }
      if (name === 'user') {
        this.$router.push({
          name: 'memberDetail',
          params: {
            id: data.id
          }
        })
      }
      if (name === 'host') {
        this.$router.push({
          name: 'buddyManagerBuddyDetail',
          params: {
            id: data.id
          }
        })
      }
    },
    // 点击操作按钮
    async handleClick (type, data) {
      this.$router.push({
        name: 'ordersManagerCouponOrdersDetail',
        params: {
          id: data.id.value
        }
      })
    },
    // // 操作已选项
    // chooseDropItem (val) {
    //   const data = this.dropDataItem.filter(item => item.name === val)
    //   console.log(data)
    //   const ids = []
    //   this.selectionData.forEach(item => {
    //     ids.push(item.id.value)
    //   })
    //   Utils.custOperate(data[0], this, { ids })
    // },
    // // tab切换
    // tabChange (val) {
    //   const index = this.dropDataItem.findIndex(item => item.value === this.tabData[val].value)
    //   const data = _.cloneDeep(this.deepDropItem)
    //   if (index !== -1) {
    //     data.splice(index, 1)
    //     this.dropDataItem = data
    //   } else {
    //     this.dropDataItem = data
    //   }
    // },
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      getCounponOrderQueryList(this.searchData).then(res => {
        if (res.data) {
          this.tableData = res.data.objects
          this.tabData = res.data.scopes
          Object.assign(this.pageData, res.data.pagination)
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleExport () {
      this.$refs.searchForm.commonOperate(getCouponOrderExport, (data) => {
        this.$router.push({
          name: 'afterSalesOrdersDownloadCenter',
          params: { data }
        })
      }, this)
    }
  }
}
</script>

<style>

</style>
